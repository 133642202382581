import React, { Component, useEffect } from "react";
import { graphql, Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import BEMHelper from "react-bem-helper";

import Layout from "../components/layout";
import SEO from "../components/seo";
import SwiftType from "../components/swift-type";
import ModuleSubmenuNav from "../modules/module-submenu-nav";
import HeaderSolidColor from "../modules/headers/header-solid-color";
import MobileMenuArrow from "../blocks/mobile-menu-arrow";

import moduleConductor from "../modules/module-conductor";

import { slugify } from "../helpers/helpers";

const TemplateSolutionsListing = ({ data, location, pageContext }) => {
  // console.log("location.href: ", data);
  const page = data.contentfulTemplateListingPage;
  const header = data.contentfulTemplateListingPage.header;
  const metaTitle = page?.seoPageTitle || page?.pageTitle;
  let headerMarkup = null;

  if (header)
    headerMarkup = moduleConductor(header, 0, {
      slug: data.contentfulTemplateListingPage.slug,
    });

  const recentInsights = data.recentInsights ? data.recentInsights.nodes : null;

  const modules = data.contentfulTemplateListingPage.addPageModules;
  let modulesMarkup = null;
  let submenuLinks = [];
  if (modules) {
    modulesMarkup = modules.map((module, i) => {
      if (module.submenuTitle) {
        submenuLinks.push(module.submenuTitle);
      }
      return moduleConductor(module, i, recentInsights);
    });
  }
  // console.log("sub ", submenuLinks);

  useEffect(() => {
    // if (!document.getElementById("loader_wrap").classList.contains("loaded")) {
    //   document.getElementById("loader_wrap").classList.remove("d-none");
    // }
    // Loader();
    // setTimeout(() => {
    //   document.getElementById("loader").classList.add("active");
    // }, 200);
    // setTimeout(function () {
    //   document.getElementById("loader_wrap").classList.add("loaded");
    //   document.getElementById("loader_wrap").classList.add("d-none");
    // }, 4000);
  }, []);

    useEffect(() => {
        const obj = {
            url: location.href,
            contentType: "solutions",
            pageType: "section",
            pageTitle: 'Litigation & Investigations',
            sbu: "LIT",
            form: "no",
            contentPublicationDate: "2022-03-18T08:06:38Z"
        };
        window?.dataLayer?.push(obj);
    }, []);

  const Loader = () => {
    let loaderDiv = document.getElementById("loader");
    let delay = 0.0003;

    for (let x = 0; x < 64; x++) {
      let width = x * 1.5625 + "%";
      let div = document.createElement("div");
      loaderDiv.appendChild(div);
      div.style.left = width;
      div.style.top = 0;
      div.style.animationDelay = (x - 32) * (x - 32) * delay + "s";
    }
  };

  const bem = new BEMHelper({
    name: "navigation",
  });

  const items =
    page.addSolutions && page.addSolutions.length > 0
      ? Array.from(page.addSolutions, (solution, i) => {
          // console.log("slug?? ", nav.slug);
          return {
            id: `${i}`,
            label: solution.title,
            description: solution.desc ? solution.desc.desc : "",
            link: `/${page.slug}/${solution.slug}`,
            items:
              solution.subSolutions && solution.subSolutions.length > 0
                ? Array.from(solution.subSolutions, (subPage, i) => ({
                    id: `${i}`,
                    label: subPage.title,
                    description: subPage.desc ? subPage.desc.desc : "",
                    link: `/${page.slug}/${solution.slug}/${subPage.slug}`,
                    items: [],
                  }))
                : [],
          };
        })
      : [];

  // const { contentTypes, solutions, tags } = props;

  const testContentTypes = ["Insight"];
  const testSolutions = ["Digital Legal Transformation"];
  const testTags = ["Tag 1", "COVID-19"];

  return (
    <Layout location={location}>
      <SEO
        title={`${metaTitle}` || "Solutions | UnitedLex"}
        description={"Browse UnitedLex legal solutions for the modern age."}
        url={"https://www.unitedlex.com/solutions"}
      />
      <SwiftType
        contentTypes={testContentTypes}
        solutions={testSolutions}
        tags={testTags}
      />
      {/* {headerMarkup} */}

      <HeaderSolidColor
        headerTitle={data.contentfulTemplateListingPage.pageTitle}
        headerDescription={
          data.contentfulTemplateListingPage.pageDescription.pageDescription
        }
      />

      {submenuLinks.length > 0 ? (
        <ModuleSubmenuNav
          items={submenuLinks.length > 0 ? submenuLinks : null}
        />
      ) : null}

      {items && items.length > 0 && (
        <div className="template-solutions-listing__submenu">
          <ul {...bem("sublist-item-desktop", "solution-submenu")}>
            {/* renders the first list item in a different container */}
            <li {...bem("solution-list-item-first")} role="none">
              <span {...bem("solution-list-label")}>{items[0].label}</span>
              {items[0].description && (
                <span {...bem("solution-list-description")}>
                  {items[0].description}
                </span>
              )}
              <Link
                to={items[0].link}
                title={items[0].label}
                role="menuitem"
                {...bem("solution-link-explore")}
              >
                Explore
                <MobileMenuArrow className="solution-arrow" />
              </Link>
            </li>
            <ul {...bem("solution-sublist-secondary-items-container")}>
              {/* renders the remaining list items */}
              {items.map(({ id, label, link, description }, i) => {
                // skips the first item, rendered alone
                if (i === 0) {
                  return;
                }
                return (
                  <li
                    {...bem("solution-secondary-sublist-item")}
                    role="none"
                    key={`solution-sublist-list-item-${id}`}
                  >
                    <Link
                      to={link}
                      title={label}
                      role="menuitem"
                      {...bem("solution-sublist-link-desktop")}
                    />
                    <span {...bem("solution-sublist-label")}>{label}</span>

                    {description && (
                      <span {...bem("solution-list-description-desktop")}>
                        {description}
                      </span>
                    )}
                  </li>
                );
              })}
            </ul>
          </ul>
        </div>
      )}
      {modulesMarkup}
      {page.addCtaBanner && moduleConductor(page.addCtaBanner, 0)}
    </Layout>
  );
};

export default TemplateSolutionsListing;

export const pageQuery = graphql`
  query($slug: String!) {
    contentfulTemplateListingPage(slug: { eq: $slug }) {
      pageTitle
      seoPageTitle
      doNotIndex
      navbarVanityName
      pageDescription {
        pageDescription
      }
      slug
      addCtaBanner {
        ...ModuleCtaBannerFragment
      }
      addPageModules {
        ...ModuleExpandingCardsFragment
        ...ModuleTwoColumnCardsFragment
        ...ModuleCtaBannerFragment
        ...ModuleCarouselFragment
        ...ModuleRelatedContentFragment
        ...ModuleLocationsMapContentfulFragment
        ...ModuleInsightBannerFragment
        ...ModuleFeaturedBannerFragment
      }
      addSolutions {
        ... on Node {
          ... on ContentfulTemplateSolution {
            __typename
            title: navbarVanityName
            desc: navbarShortDescription {
              desc: navbarShortDescription
            }
            slug
            header {
              ...ModuleHeaderSimpleFragment
              ...ModuleHeaderWithMediaFragment
            }
          }
        }
      }
    }
  }
`;
